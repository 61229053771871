@font-face {
  font-family: 'Averta CY Regular';
  src: local('Averta CY'), url(../assets/fonts/Averta\ Cyrillic\ Regular.otf) format('opentype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}
@font-face {
  font-family: 'Averta CY Regular Italic';
  src: local('Averta CY'), url(../assets/fonts/Averta\ Cyrillic\ Regular\ Italic.otf) format('opentype');
}
@font-face {
  font-family: 'Averta CY Black';
  src: local('Averta CY'), url(../assets/fonts/Averta\ Cyrillic\ Black.otf) format('opentype');
}
@font-face {
  font-family: 'Averta CY Black Italic';
  src: local('Averta CY'), url(../assets/fonts/Averta\ Cyrillic\ Black\ Italic.otf) format('opentype');
}
@font-face {
  font-family: 'Averta CY Bold';
  src: local('Averta CY'), url(../assets/fonts/Averta\ Cyrillic\ Bold.otf) format('opentype');
}
@font-face {
  font-family: 'Averta CY Bold Italic';
  src: local('Averta CY'), url(../assets/fonts/Averta\ Cyrillic\ Bold\ Italic.otf) format('opentype');
}
@font-face {
  font-family: 'Averta CY Extra Thin';
  src: local('Averta CY'), url(../assets/fonts/Averta\ Cyrillic\ Extra\ Thin.otf) format('opentype');
}
@font-face {
  font-family: 'Averta CY Extra Thin Italic';
  src: local('Averta CY'), url(../assets/fonts/Averta\ Cyrillic\ Extra\ Thin\ Italic.otf) format('opentype');
}
@font-face {
  font-family: 'Averta CY ExtraBold';
  src: local('Averta CY'), url(../assets/fonts/Averta\ Cyrillic\ ExtraBold.otf) format('opentype');
}
@font-face {
  font-family: 'Averta CY ExtraBold Italic';
  src: local('Averta CY'), url(../assets/fonts/Averta\ Cyrillic\ ExtraBold\ Italic.otf) format('opentype');
}
@font-face {
  font-family: 'Averta CY Light';
  src: local('Averta CY'), url(../assets/fonts/Averta\ Cyrillic\ Light.otf) format('opentype');
}
@font-face {
  font-family: 'Averta CY Light Italic';
  src: local('Averta CY'), url(../assets/fonts/Averta\ Cyrillic\ Light\ Italic.otf) format('opentype');
}
@font-face {
  font-family: 'Averta CY SemiBold';
  src: local('Averta CY'), url(../assets/fonts/Averta\ Cyrillic\ SemiBold.otf) format('opentype');
}
@font-face {
  font-family: 'Averta CY SemiBold Italic';
  src: local('Averta CY'), url(../assets/fonts/Averta\ Cyrillic\ SemiBold\ Italic.otf) format('opentype');
}
@font-face {
  font-family: 'Averta CY Thin';
  src: local('Averta CY'), url(../assets/fonts/Averta\ Cyrillic\ Thin.otf) format('opentype');
}
@font-face {
  font-family: 'Averta CY Thin Italic';
  src: local('Averta CY'), url(../assets/fonts/Averta\ Cyrillic\ Thin\ Italic.otf) format('opentype');
}
